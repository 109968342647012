import React from "react";

function CheckWords({ text }) {
  return (
    <div className="flex space-x-4 items-center">
      <div className="bg-[#2f4959]  h-8 w-8 rounded-full flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#ffffff"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <p className="lg:w-[200px] text-white font-light leading-[30px]">
        {text}
      </p>
    </div>
  );
}

export default CheckWords;
