import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/global.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import CheckWords from "../components/Checkwords";

import Helmet from "react-helmet";

// markup
const IndexPage = () => {
  return (
    <div>
      <Helmet>
        <title>Haarth - </title>
      </Helmet>

      <main className="text-[#152e3a]">
        <div className="bg-[#152e3a]">
          <section className="w-[82%] text-sm text-white mx-auto lg:h-10 lg:flex items-center justify-between">
            <div className="py-3 flex items-center lg:space-x-8 space-x-3">
              <div className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#ff5e15"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
                  />
                </svg>
                <p>info@haarth.co.uk</p>
              </div>
              <div className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#ff5e15"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <p>London</p>
              </div>
            </div>
            <div className="flex items-center justify-center h-full space-x-8 pb-3 lg:pb-0">
              <div className="flex items-center space-x-2"></div>
              <button className="bg-[#ff5e15] h-full px-6 py-1">
                Get A Job?
              </button>
            </div>
          </section>
        </div>
        <section className="lg:h-28 z-10 lg:sticky top-0 lg:pt-0 pt-3 bg-white lg:shadow-xl">
          <div className="flex flex-col lg:flex-row pt-6 lg:pt-0 text-lg w-[82%] mx-auto items-center h-full justify-between">
            <StaticImage src="../images/logo.png" class="w-[200px]" />
            <div className="lg:flex items-center">
              <div className="hidden lg:flex lg:space-x-8">
                <p>Home</p>
                <p>About</p>
                <p>Portfolio</p>
                <p>Contact</p>
              </div>
            </div>
          </div>
        </section>

        <div>
          <section className="h-[520px] bg-[#f2f6ff]">
            <div className="lg:w-[82%] px-5 lg:px-0 mx-auto lg:flex items-center h-full">
              <div className="lg:w-1/2 space-y-10">
                <div className="pt-28 lg:pt-0">
                  <p className="flex items-center mb-4 text-[15px] text-[#ff5e15] font-bold lg:text-sm">
                    <span className="text-left mr-1 h-5 w-5 block bg-[#ff5e15]"></span>
                    GREAT EXPERIENCE IN BUILDING
                  </p>
                  <p className="lg:text-[45px] text-[30px] text-[#0b1e33] font-semibold lg:w-[79%] w-[90%] lg:tracking-wider lg:leading-[50px] font-serif">
                    Leaders in delivering quality
                  </p>
                </div>
                <div className="lg:pl-10 pl-2 border-l-2 border-[#ff5e15] text-[17px] lg:text-lg">
                  Customer satsfaction ai at the core of our values and we pride
                  ourselves in meeting our clients expecttions.
                </div>

                <div>
                  <button className="lg:py-5 py-2 lg:px-12 px-4 bg-[#ff5e15] text-white">
                    Make An Enquiry
                  </button>
                </div>
              </div>
              <div className="hidden h-[520px] lg:flex w-1/2 justify-center">
                <StaticImage src="../images/man.png" className="w-[274px]" />
              </div>
            </div>
          </section>
        </div>

        <div className="lg:w-[82%] px-5 lg:px-0 mx-auto">
          <section className="my-28 lg:flex">
            <div className="lg:w-1/2">
              <StaticImage
                className="h-full lg:w-[90%]"
                src="https://images.squarespace-cdn.com/content/v1/5bd4519a840b16cf9473b6da/1604414094117-NS6KW1EN2LSH9PKL5QFP/buldersyearend1jpg.jpg?format=1500w"
              />
            </div>
            <div className="lg:w-1/2 space-y-8 pt-16 lg:pt-0">
              <div>
                <p className="flex items-center mb-4">
                  <span className="mr-1 h-5 w-5 block bg-[#ff5e15]"></span>GREAT
                  EXPERIENCE IN BUILDING
                </p>
                <p className="lg:text-5xl text-xl text-[#0b1e33] font-bold lg:w-[64%] tracking-wider lg:leading-[66px] font-serif">
                  Solutions For Residentials & Industries!
                </p>
              </div>
              <div className="lgpl-10 pl-2 border-l-2 border-[#ff5e15]">
                Construction is a general term meaning the art and science to
                form objects systems organizations, and comes from Latin
              </div>
              <div>
                Construction is a general term meaning the art and science to
                form objects systems organizations, and comes from Latin
                construction and Old French construction. To construct is the
                verb: the act of building, and the noun
              </div>
              <div>
                <button className="lg:py-5 py-2 lg:px-12 px-4 bg-[#0b1e33] text-white">
                  About Us
                </button>
              </div>
            </div>
          </section>
        </div>
        {/* <div className="h-[500px] bg-[#222]">
          <div className="mx-auto w-[82%] flex items-center h-full">
            <section className="lg:w-1/2 flex flex-col justify-center h-full">
              <p className="mb-2 text-[#ff5e15]">123-456-7890/CALL</p>
              <p className="lg:text-5xl text-xl text-white font-bold tracking-wider lg:leading-[66px] font-serif">
                A Company Involved In Service, Maintenance
              </p>
              <div>
                <button className="mt-10 lg:py-5 py-2 lg:px-12 px-4 bg-[#ff5e15] text-white">
                  Get Appointment
                </button>
              </div>
            </section>
            <div className="text-white w-1/2 lg:flex justify-end hidden">
              <StaticImage
                className="h-full w-[384px] mt-[-30px]"
                src="https://pngimg.com/uploads/industrial_worker/industrial_worker_PNG11414.png"
              />
            </div>
          </div>
        </div> */}
        {/* <div>
          <section className="lg:w-[82%] px-5 lg:px-0 mx-auto py-28">
            <div className="flex flex-col items-center">
              <p className="flex items-center mb-4">
                <span className="mr-1 h-5 w-5 block bg-[#ff5e15]"></span> OUR
                SERVICES
              </p>
              <p className="lg:text-5xl text-xl text-[#0b1e33] font-bold tracking-wider font-serif">
                Construction Solution
              </p>
            </div>
            <div className="mt-12 lg:grid grid-cols-3 gap-6">
              <Card2 />
              <div className="h-8 lg:hidden" />
              <Card2 />
              <div className="h-8 lg:hidden" />
              <Card2 />
              <div className="h-8 lg:hidden" />
              <Card2 />
              <div className="h-8 lg:hidden" />
              <Card2 />
              <div className="h-8 lg:hidden" />
              <Card2 />
            </div>
          </section>
        </div> */}
        <div className="flex h-[650px] ">
          <div className="lg:w-1/2 w-full bg-[#0a2b3d] px-5 lg:px-0">
            <section className="lg:pl-[120px] pl-2 lg:pr-[40px] lg:pt-[170px] pt-[100px]">
              <h1 className="lg:text-[40px] text-[30px] text-white font-extrabold tracking-wider lg:leading-[66px] font-serif">
                Our Specialization
              </h1>

              <div className="lg:grid grid-cols-2 gap-5 pt-10">
                <CheckWords text="End of tenancy works" />
                <div className="h-8 lg:hidden" />

                <CheckWords text="Flooring" />
                <div className="h-8 lg:hidden" />

                <CheckWords text="Kitchen refurbs" />
                <div className="h-8 lg:hidden" />

                <CheckWords text="Decorations" />
                <div className="h-8 lg:hidden" />

                <CheckWords text="Brick work" />
                <div className="h-8 lg:hidden" />

                <CheckWords text="Birthroom refurbs" />
              </div>
            </section>
          </div>
          <div className="lg:w-1/2 bg-slate-200 bg-builder hidden lg:block"></div>
        </div>

        <section className="bg-[#171b2a] text-white relative">
          <footer className="pt-20">
            <div className="lg:w-[95%] px-5 lg:px-0 mx-auto lg:flex py-16">
              <div className="lg:w-[28%]">
                <StaticImage src="../images/logo2.png" class="w-[200px]" />
                <div className="py-8">
                  <p className="lg:w-[80%]">
                    Leaders in delivering quality projects.
                  </p>

                  <div className="pt-8 space-y-3">
                    <div className="flex items-start space-x-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      <p className="w-[70%]">
                        International House, E16 2DQ, London, United Kingdom
                      </p>
                    </div>
                    <div className="flex items-start space-x-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                        />
                      </svg>
                      <p>+0123-456789</p>
                    </div>
                    <div className="flex items-center space-x-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                      <p>info@haart.co.uk</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-[15%]">
                <h1 className="font-extrabold tracking-wider text-2xl font-serif">
                  Company
                </h1>
                <div className="pt-8 space-y-4">
                  <p>About</p>
                  <p>Contact us</p>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </main>
    </div>
  );
};

export default IndexPage;
